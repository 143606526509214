import { defineStore } from 'pinia'

import { PaginationParams } from '@/models/api'

import * as reviewsApi from '@/api/reviews'

import { parseErrors } from '@/helpers/error-parsers'
import {
  getInitialDataModuleState,
  handleDataModuleState,
  handlePaginationModuleState,
  handleCommonModuleState,
} from '@/helpers/store-state'

import { GetReviewsParams } from './types'
import { getInitialReviewsStoreState } from './state'

export const useReviewsStore = defineStore('reviews-store', {
  state: getInitialReviewsStoreState,
  actions: {
    async getReviews(params: PaginationParams & GetReviewsParams) {
      try {
        this.$patch(state => {
          handlePaginationModuleState('start', state.listModule)
        })

        const {
          data: { data: list, meta },
        } = await reviewsApi.getReviews(params)

        this.$patch(state => {
          handlePaginationModuleState('success', state.listModule, {
            list,
            meta,
          })
        })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch(state => {
          handlePaginationModuleState('error', state.listModule, error)
        })
      }
    },

    async getReviewDetails(id: string) {
      try {
        if (!this.reviewsDetails[id]) {
          this.reviewsDetails[id] = getInitialDataModuleState()
        }

        this.$patch(state => {
          const details = state.reviewsDetails[id]
          if (details) handleDataModuleState('start', details)
        })

        const review = (await reviewsApi.getReviewDetails(id)).data.data

        this.$patch(state => {
          const details = state.reviewsDetails[id]
          if (details) handleDataModuleState('success', details, review)
        })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch(state => {
          const details = state.reviewsDetails[id]
          if (details) handleDataModuleState('error', details, error)
        })
      }
    },

    async deleteReview(id: string) {
      try {
        this.$patch(state => {
          handleCommonModuleState('start', state.listModule)
        })

        await reviewsApi.deleteReview(id)

        this.$patch(state => {
          handleCommonModuleState('success', state.listModule)
        })
      } catch (e) {
        const { error } = parseErrors(e)
        this.$patch(state => {
          handleCommonModuleState('error', state.listModule, error)
        })
      }
    },
  },
})
