import client from './index'

import {
  ApiPaginationResponse,
  ApiResponse,
  PaginationParams,
} from '@/models/api'
import { ReviewWithRelations } from '@/models/review'

import { GetReviewsParams } from '@/store/reviews'

export const getReviews = (params: PaginationParams & GetReviewsParams) => {
  return client.get<ApiPaginationResponse<ReviewWithRelations>>('/reviews', {
    params,
  })
}

export const deleteReview = (id: string) => {
  return client.delete<ApiResponse<void>>(`/reviews/${id}`)
}

export const getReviewDetails = (id: string) => {
  return client.get<ApiResponse<ReviewWithRelations>>(`/reviews/${id}`)
}
